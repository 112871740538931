const linkResolver = (doc = {}) => {
  if (!doc.type) {
    return linkResolver;
  }

  const linkMap = {
    home_page: "/",
    sales_sheets: `/sales-sheets/${doc.uid}`,
  };

  return linkMap[doc.type] || "/";
};

module.exports = linkResolver;
