import React, { useEffect } from "react";
import { useAppState } from "@state/state";
import { navigate } from "gatsby";
import { usePrismicPreview } from "gatsby-source-prismic";
import { Text, Image } from "@components";
import { useLogoData } from "@queries";
import linkResolver from "../prismic/linkResolver";

/* eslint react/prop-types: 0 */

const Preview = () => {
  const { logo } = useLogoData();
  const { previewData, path } = usePrismicPreview({
    repositoryName: process.env.GATSBY_PRISMIC_REPO,
    linkResolver,
  });
  const [, dispatch] = useAppState();

  useEffect(() => {
    if (previewData && path) {
      window.__PRISMIC_PREVIEW_DATA__ = previewData;
      dispatch({
        type: "setPreviewMode",
        previewMode: true,
      });
      navigate(path);
    }
  }, [previewData, path]);

  return (
    <div className="fixed inset-0 flex flex-col justify-center items-center bg-gray-3 z-preview">
      <div className="mb-6">
        <div
          style={{ width: 100, height: 100 }}
          className="relative inline-block rounded-lg overflow-hidden mb-6"
        >
          <Image image={logo} objectFit="contain" />
        </div>
      </div>
      <Text preset="h3" className="text-white capitalize">
        Loading preview...
      </Text>
    </div>
  );
};

export default Preview;
